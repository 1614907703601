import axios from 'axios';

export const apiURL = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return process.env.REACT_APP_HTTPS
        ? 'https://local.api.enkicamp.com:1234'
        : 'http://localhost:1234';
    case 'staging':
      return 'https://staging.api.enkicamp.com';
    case 'production':
      return 'https://api.enkicamp.com';
    default:
      return 'http://localhost:1234';
  }
})();

export const learnerDashboardURL = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return 'http://localhost:3000';
    case 'staging':
      return 'https://staging.learn.enki.com';
    case 'production':
      return 'https://learn.enki.com';
    default:
      return 'http://localhost:3000';
  }
})();

axios.defaults.baseURL = apiURL;
axios.defaults.headers.common['Accept-Encoding'] = 'gzip,identity';

export async function getHealthcheck() {
  const response = await axios({
    method: 'get',
    url: '/healthcheck',
  });
  return response.data;
}

export async function verifyAuthUser(token: string) {
  const response = await axios({
    method: 'get',
    url: '/verify-user',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}