import useAsyncRetryWithCrashReporter from './use-async-retry-with-crash-reporter';
import useAuth0 from './use-auth0';
import {
  getHealthcheck,
  verifyAuthUser
} from '../hydra';

export function useHealthcheck() {
  const state = useAsyncRetryWithCrashReporter(async () => {
    const result = await getHealthcheck();
    return result;
  }, []);

  return state;
}

export function useVerifyAuthUser() {
  const { isAuthenticated, getTokenSilently, isLoading } = useAuth0();

  const state = useAsyncRetryWithCrashReporter(async () => {
    if (isLoading) {
      return null;
    }

    // we only verify authenticated users
    // if a user is not authenticated by auth0
    // we skip our own auth verification
    if (!isAuthenticated) {
      return null;
    }

    const token = await getTokenSilently();
    if (!token) {
      return;
    }
    const authUser = await verifyAuthUser(token);
    return authUser;
  }, [isAuthenticated, isLoading]);

  return state;
}
