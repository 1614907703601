import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import useCleanGlobalClasses from '../hooks/use-clean-global-classes';
import theme from '../theme';
import PageWrapper from '../components/PageWrapper';
import Navbar from '../components/Navbar';
import Loader from '../components/Loader';
import useAuth0 from '../hooks/use-auth0';
import Alert from '../components/Alert';

const PageContentWrapper = styled.div`
  padding: 2em;
  background-color: ${prop('theme.colors.sectionBg')};
  border: 1px solid ${prop('theme.colors.sectionBorder')};
  border-radius: ${prop('theme.dimensions.sectionBorderRadius')};
`;

export default function Home() {
  const { isLoading, error, auth0User } = useAuth0();

  useCleanGlobalClasses({ isLoading });

  if (isLoading) {
    return <Loader color={theme.colors.primary} />;
  }

  if (error) {
    return <Alert type="error" text={error.message} />;
  }

  if (!auth0User) {
    return <Redirect to="/auth" />;
  }

  return <AuthenticatedHome />;
}

function AuthenticatedHome() {
  const {
    isLoading: isLoadingAuth0,
    isAuthenticated,
    auth0User,
    logout: auth0Logout,
  } = useAuth0();

  // if (isLoadingAuth0) {
  //   return <Loader color={theme.colors.primary} />;
  // }

  return (
    <>
      <Navbar
        auth={{
          isLoading: isLoadingAuth0,
          isAuthenticated,
          user: auth0User,
          logout: auth0Logout,
        }}
      />
      <PageWrapper>
        <PageContentWrapper>
          <h2>Champion Home</h2>
        </PageContentWrapper>
      </PageWrapper>
    </>
  );
}
