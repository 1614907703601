import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled, { ThemeProvider } from 'styled-components';
import Loader from '../components/Loader';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import NotFound from '../components/NotFound';
import Home from './Home';
import Auth from './Auth';
import { Auth0Provider } from '../contexts/auth0';
import useAuth0 from '../hooks/use-auth0';
import { AuthProvider } from '../contexts/auth';
import useAuth from '../hooks/use-auth';
import { useHealthcheck } from '../hooks/use-hydra';
import { ButtonWrapper } from '../components/Button';
import theme from '../theme';
import * as CrashReporter from '../crash-reporter';
import * as Analytics from '../analytics';
import { CacheLocation } from '@auth0/auth0-spa-js';
import RequestError from '../components/RequestError';
import { getHomeUrl } from '../routing';

const ErrorWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > ${ButtonWrapper}, > a {
    margin-top: 2em;
  }
`;

function RenderError() {
  return (
    <ErrorWrapper>
      <h1>Unfortunately, something went wrong</h1>
    </ErrorWrapper>
  );
}

function HydraAuthenticatedApplication() {
  const { isLoading, error, retry, user } = useAuth();

  React.useEffect(() => {
    if (user) {
      CrashReporter.setUser(user);
      Analytics.setUser(user);
      Analytics.trackEvent({ event: Analytics.EVENTS.AUTHENTICATE });
    }
  }, [user]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return RequestError({ error, retry });
  }

  return (
    <Switch>
      <Route exact path={getHomeUrl()}>
        <Home />
      </Route>
      <AuthenticatedRoute exact path="/auth" targetUrl="/">
        <Auth />
      </AuthenticatedRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

function Auth0AuthenticatedApplication() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loader />;
  }
  return (
    <AuthProvider>
      <HydraAuthenticatedApplication />
    </AuthProvider>
  );
}

const auth0Config = {
  domain: 'enkidevs.auth0.com',
  client_id: 'wdW9AB82PJ1xrI1gHSOkXGv3NjkdCl47',
  audience: 'https://api.enkicamp.com',
  // for the two settings below, see: https://www.notion.so/enki/Users-cannot-login-on-Safari-or-Chrome-Incognito-c902fdfbabbd4906b4fa44d5e24b94fa
  cacheLocation: 'localstorage' as CacheLocation,
  useRefreshTokens: true,
};

function AuthenticatedApplication() {
  const history = useHistory();

  const { loading, error, value, retry } = useHealthcheck();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return RequestError({ error, retry });
  }

  console.info(`Backend: ${value}`);

  return (
    <Auth0Provider
      config={auth0Config}
      redirect_uri={window.location.origin}
      onRedirectCallback={(appState) => {
        history.replace(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        );
      }}
    >
      <Auth0AuthenticatedApplication />
    </Auth0Provider>
  );
}

function RoutedApplication() {
  return (
    <Route path="/">
      <ToastContainer
        position="top-center"
        className="enki-toast-container"
        toastClassName="enki-toast"
        progressClassName="enki-toast-progress"
      />
      <Switch>
        {/* public routes can go here */}
        <AuthenticatedApplication />
      </Switch>
    </Route>
  );
}

function Application() {
  return (
    <CrashReporter.ErrorBoundary FallbackComponent={RenderError}>
      <Router>
        <ThemeProvider theme={theme}>
          <RoutedApplication />
        </ThemeProvider>
      </Router>
    </CrashReporter.ErrorBoundary>
  );
}

export default Application;
